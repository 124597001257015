import api from './api';

// 团购列表
export const $sp_pageGroupGoods = (params) => api.get('/sssk/api/platform/biz/groupGoods/pageGroupGoods', params);
// 上下架团购
export const $sp_toggleSaleState = (params) => api.post('/sssk/api/platform/biz/groupGoods/toggleSaleState', params);
// 团购详情
export const $sg_getGroupGoodsDetail = (params) => api.get('/sssk/api/platform/biz/groupGoods/getGroupGoodsDetail', params);
// 下拉商户列表
export const $sg_listSelectShopInfo = (params) => api.get('/sssk/api/platform/biz/groupGoods/listSelectShopInfo', params);
// 获取团购分类下拉列表
export const $sg_getMchGroupCatSelect = (params) => api.get('/j/agent/mchGroupGoods/getMchGroupCatSelect', params);
// 更新团购
export const $sp_updateGroupGoods = (params) => api.post('/sssk/api/platform/biz/groupGoods/updateGroupGoods', params);
// 获取团购预览信息
export const $sg_getGroupGoodsPreviewInfo = (params) => api.get('/sssk/api/platform/biz/groupGoods/getGroupGoodsPreviewInfo', params);
// 审核操作
export const $sp_groupGoodsaudit = (params) => api.post('/sssk/api/platform/biz/groupGoods/audit', params);
