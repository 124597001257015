<template>
  <el-dialog width="500px" :visible='showPreview' class="previewDialog" @close="closePreviewDialog">
    <p slot="title" class="previewTitle">商品详情预览</p>
    <div class="previewContent">
      <img v-if="this.form.goodsPath" :src="this.form.goodsPath" alt="">
      <img v-else src="#" alt="">
      <div class="content">
        <div class="goodsInfo cardStyle">
          <p class="price"><span style="color:#F63D29;font-size:18px;">￥{{form.groupPrice||0}}</span><span>￥{{form.price||0}}</span></p>
          <p class="goodsTitle">【{{form.goodsName||'未填写'}}】{{form.remark||'未填写'}} </p>
        </div>
        <div class="shopInfo cardStyle">
          <p class="title">门店信息</p>
          <div class="info">
            <p> {{form.shopName||'未选择'}} <span style="font-size:14px;color:#444;margin-left:5px;">{{12}}km</span></p>
            <p> <i class="el-icon-location-outline"></i> {{form.address||'未填写'}}</p>
          </div>
        </div>
        <div class="watchOut cardStyle">
          <p class="title">购买须知</p>
          <div>
            <p>1.商品详情仅供参考，具体以现场为准。</p>
            <p>2.团购券具有唯一性/时效性等特殊属性，如非商家原因，平台原因都在超出有效期之后，团购将自动作废。</p>
            <p>3.关于退款，在团购的有效期前,为核销的券都可以申请退款，退款通常会在1-3个工作日原路返回支付账户。</p>
            <p>4.如果对订单或付款产生疑问，请致电0281302353</p>
          </div>
        </div>
        <div class="goodsDetail">
          <p class="title">商品详情</p>
          <div class="teditorHtml" v-html="form.goodsDetail">

          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  $sg_getGroupGoodsPreviewInfo,
} from '@/api/totalgroup';

export default {
  data() {
    return {
      showPreview: false,
      form: {
        address: '',
        goodsDetail: '',
        goodsName: '',
        goodsPath: '',
        groupPrice: '',
        id: '',
        mapLat: '',
        mapLon: '',
        price: '',
        serviceTel: '',
        shopName: '',
        remark: '',
      },
    };
  },
  props: {
    // form: {
    //   type: Object,
    //   default() {
    //     return { item: [] };
    //   },
    // },
  },
  methods: {
    openDialog() {
      this.showPreview = true;
      console.log(66666);
    },
    closePreviewDialog() {
      this.showPreview = false;
    },
    getTableData(id) {
      const params = {
        groupGoodsId: id,
      };
      $sg_getGroupGoodsPreviewInfo(params).then((res) => {
        this.form = res;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.previewDialog {
  img {
    width: 100%;
    height: 350px;
    background: #dfdfdf;
    border-radius: 10px;
  }
  .cardStyle {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    background: #fff;
    border-radius: 10px;
    margin-top: 10px;
  }
  .title {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    padding-bottom: 9px;
    border-bottom: 1px solid #f0f0f0;
  }
  .previewTitle {
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    line-height: 25px;
    color: #333333;
  }
  .previewContent {
    height: 600px;
    overflow-y: scroll;
    margin-left: 40px;
    width: 375px;
    background: #f5f5f5;
    .content {
      box-sizing: border-box;
      padding: 10px;
      .goodsInfo {
        .price {
          margin-bottom: 8px;
          span:nth-child(2) {
            box-sizing: border-box;
            margin-left: 5px;
            color: #999999;
            text-decoration: line-through;
          }
        }
        .goodsTitle {
          font-size: 16px;
          font-weight: bold;
        }
      }
      .shopInfo {
        .info {
          flex: 1;
          color: #666666;
        }
      }
      .watchOut {
        p {
          margin-top: 15px;
          color: #333333;
        }
      }
      .goodsDetail {
        .teditorHtml /deep/img {
          width: 120px;
          position: relative;
          height: 62px;
        }
      }
    }
  }
}
</style>
