<template>
  <div class="groupBuyingGoods">
    <div class="basicInfo">
      <div class="title">基础信息</div>
      <div class="goodss">

        <div>商品封面</div>
        <ImgBox :imgList='form.loopImgArr' :disabled='disabled' @dataFormImgBox='receive'></ImgBox>
      </div>

      <div class="basicBox mt30 ">
        <div class="leftContent">

          <!-- <span class="label">分类</span>
           <el-select v-model="form.groupCatName" style="width: 300px;margin: 0px 10px;" clearable placeholder="选择分类" :disabled="disableds">
              <el-option v-for="item in catOptions" :key="item.groupCatId" :label="item.groupCatName" :value="item.groupCatId">
              </el-option>
            </el-select> -->
          <div class="flexbox flex-align-center mt30">
            <span class="label">分类</span>
            <el-input v-model="form.groupCatName" style="width: 300px;margin: 0px 10px;" placeholder="输入团购商品标题" disabled :maxlength="30"
              show-word-limit></el-input>
          </div>

          <div class="flexbox flex-align-center mt30">
            <span class="label">团购商品标题</span>
            <el-input v-model="form.onlyName" style="width: 300px;margin: 0px 10px;" placeholder="输入团购商品标题" :disabled="disabled"
              :maxlength="30" clear></el-input>
          </div>

        </div>
        <div class="middleContent">

          <div class="flexbox flex-align-center mt30">
            <span class="label">购买日期</span>
            <el-date-picker v-model="buyTime" class="ml10" type="daterange" range-separator="到" start-placeholder="开始日期"
              end-placeholder="结束日期" value-format='yyyy-MM-dd' disabled>
            </el-date-picker>
          </div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">使用时间</span>
            <el-date-picker v-model="useTime" class="ml10" type="daterange" range-separator="到" start-placeholder="开始日期"
              end-placeholder="结束日期" value-format='yyyy-MM-dd' disabled>
            </el-date-picker>
          </div>
          <div class="tips mt20">注：此为用户团购商品截止时间（下单到失效）</div>
        </div>
        <div class="rightContent">
          <div class="flexbox flex-align-center mt30">
            <span class="label">商品描述</span>
            <el-input type="textarea" v-model="form.remark" style="width: 300px;margin: 0px 10px;" placeholder="商品描述" :disabled="disabled"
              :maxlength="300" show-word-limit></el-input>
          </div>
          <div class="flexbox flex-align-center mt30 ">
            <span class="label">限购规则</span>
            <el-radio v-model="form.singleMax" :label="0" style="margin: 0px 10px;" disabled>不限购</el-radio>
            <el-radio v-model="form.singleMax" :label="form.singleMax" disabled>限购</el-radio>
          </div>
          <div class="flexbox flex-align-center mt30" v-if="form.singleMax > 0">
            <span class="label">每人限购</span>
            <el-input v-model="form.singleMax" style="width: 300px;margin: 0px 10px;" placeholder="请输入每人限购" disabled type="number">
            </el-input>
            <span>份</span>
          </div>
        </div>
      </div>
    </div>
    <div class="specificationInfo">
      <div class="title">规格信息</div>
      <div class="content">
        <p class=""><span>商品规格</span><span>商品价格</span><span>库存规则</span></p>
        <div class="specificationForm" v-for=" (item,index) in form.items" :key="index">
          <div class="flexbox flex-align-center">
            <span class="label">规格名称</span>
            <el-input v-model="item.subTitle" style="width:200px;margin: 0px 10px;" disabled>
            </el-input>
          </div>
          <div class="middleContent flexbox">
            <span></span>
            <span></span>
            <div class="flexbox flex-align-center mr20 ">
              <span class="label">团购价</span>
              <el-input v-model="item.salePrice" type="number" style="width:150px;margin: 0px 10px;" placeholder="团购价" :disabled="disabled">
              </el-input>
              <span>元</span>
            </div>
            <div class="flexbox flex-align-center mr20 ">
              <span class="label">成本价</span>
              <el-input v-model="item.channelPrice" type="number" style="width: 150px;margin: 0px 10px;" placeholder="商品原价" disabled>
              </el-input>
              <span>元</span>
            </div>
            <div class="flexbox flex-align-center  ">
              <span class="label">原价</span>
              <el-input v-model="item.originPrice" type="number" style="width: 150px;margin: 0px 10px;" placeholder="商品原价"
                :disabled="disabled">
              </el-input>
              <span>元</span>
            </div>
          </div>
          <div class="rightContent">
            <!-- <div class="flexbox flex-align-center mt30">
              <span class="label">库存规则</span>
              <el-radio v-model="item.limitStockType" :label="0" style="margin: 0px 10px;" :disabled="disabled">不限库存</el-radio>
              <el-radio v-model="item.limitStockType" :label="1" :disabled="disabled">限制库存</el-radio>
            </div>
            <div class="flexbox flex-align-center mt30" v-if="item.limitStockType === 1"> -->
            <div class="flexbox flex-align-center mt30">
              <span class="label">库存量</span>
              <el-input v-model="item.itemChannelStock" type="number" style="width: 200px;margin: 0px 10px;" placeholder="请输入库存量" disabled>
              </el-input>
            </div>
          </div>
          <!-- <button class="delectForm"  @click="deleteSpecificationsInfo(index)">删除</button> -->
        </div>
        <!-- <el-button type="default" :disabled='disabled' @click="addSpecificationsInfo">添加</el-button> -->
      </div>
    </div>
    <div class="goodsDetail">
      <div class="title">
        <span>商品详情</span>
        <span>注：可以直接上传多张长图或编辑文本，长图建议宽度为375px</span>
      </div>
      <div class="mt20">
        <TEditor class="editor" type="complete" v-model="form.productTitle" v-if="flag" />
      </div>
      <div class="btnBox flexbox flex-align-center flex-justify-center" v-if="!disabled">
        <el-button class="btn" type="primary" @click="preview">预览</el-button>
        <el-button class="btn" type="primary" @click="openSubmitDialog">保存并发布</el-button>
        <PrevReclick>
        </PrevReclick>
        <el-button class="btn" type="info" @click="$router.go(-1)">取消</el-button>
      </div>
      <div v-if="returns===3" class="flexbox  flex-justify-center mt20 returns">
        <el-button class="btn" type="primary" @click="$router.go(-1)">返回</el-button>
      </div>
    </div>

    <!-- 保存并发布提示弹窗 -->
    <Dialog ref="dialog" dialogWidth="500px">
      <div class="dialogBtn flexbox flex-align-center flex-justify-center">
        <PrevReclick>
          <el-button class="btn" type="primary" @click="submit">确认</el-button>
        </PrevReclick>
        <el-button class="btn" type="info" @click="$refs.dialog.showDialog = false">放弃</el-button>
      </div>
    </Dialog>
    <!-- 发布结果提示弹窗 -->
    <Dialog ref="resultDialog" dialogWidth="500px" @doneBtn="doneBtn"></Dialog>
    <!-- 预览弹窗 -->
    <Preview :form="form" ref="previewDialog"></Preview>
  </div>
</template>
<script>
import TEditor from '@/components/common/TEditor.vue';
import Dialog from '@/components/common/dialog.vue';
// import FileUploader from '@base/utils/file/FileUploader';
import {
  //  $sg_getMchGroupCatSelect, $sp_createGroupBuying,
  $sp_queryDetail,
  $sp_groupupdate,
} from '@/api/algroupBuying';

import {
  $agent_selectAgent,
} from '@/api/agentAll';
import ImgBox from './components/ImgBox.vue';
import Preview from './components/Preview.vue';


export default {
  data() {
    return {
      // singleMaxs: '', // 判断限购
      // singleMaxss: '', // 判断限购
      returns: '',
      row: '',
      buyTime: [],
      useTime: [],
      flag: false,
      disabled: false,
      // row: [], // 点击过来的数据
      form: {
        singleMax: '', // 限购
        onlyName: '', // 团购商品标题
        goodsName: '', // 商户
        beginTime: '', // 抢购开始时间
        endTime: '', // 抢购结束时间
        validBeginDate: '', // 购买后有效开始时间
        validEndDate: '', // 购买后有效结束时间
        agentSid: '', // 代理商id
        groupId: '', // t团购商品id
        groupName: '', // 团购名称
        remark: '', // 商品描述
        goodsPath: '', // 预览图片
        personPerLimit: '',
        period: '',
        productTitle: '', // 商品详情注
        loopImgArr: [{ // 图片数组
          url: '',
          sort: 1,
        },
        {
          url: '',
          sort: 2,
        },
        {
          url: '',
          sort: 3,
        },
        {
          url: '',
          sort: 4,
        },
        {
          url: '',
          sort: 5,
        },
        {
          url: '',
          sort: 6,
        },
        ],
        items: [// 规格信息列表
          {
            channelPrice: '', // 结算价
            itemChannelStock: '', // 库存
            originPrice: '', // 原价(分)
            salePrice: '', // 套餐售价(分)
            subTitle: '', // 套餐名称
          },
        ],
      },
      shopOptions: [],
      catOptions: [],
      code: '', // 发布结果成功还是失败
      timerId: '', // 定时器id
      showPreview: false, // 预览弹窗
    };
  },
  components: {
    ImgBox,
    TEditor,
    Dialog,
    Preview,
  },

  created() {
    // this.getMchGroupCatSelect();
    // type: 类型， 1-新增，2-编辑，3-详情
    const type = this.$route.query.type;
    // const row = this.$route.query.row;
    const row = JSON.parse(this.$route.query.row);
    this.form.groupCatName = row.categoryName;
    this.form.goodsName = row.merchantName;
    this.form.groupId = row.groupId;
    if (type === '2') {
      this.flag = true;
      this.queryGroupDetail();
    } else if (type === '3') {
      this.flag = true;
      this.queryGroupDetail();
      this.disabled = true;
      this.returns = 3;
    }
  },
  methods: {
    // 查询团购详情接口并渲染
    queryGroupDetail() {
      const groupId = this.form.groupId;
      $sp_queryDetail({ groupId }).then((res) => {
        this.flag = false;
        this.$nextTick(() => {
          this.form.productTitle = res.productTitle;
          this.flag = true;
        });
        this.form.remark = res.title;
        this.form.onlyName = res.onlyName;
        this.form.groupName = res.onlyName;
        this.buyTime = [this.$formatDate(res.beginTime, 'yyyy-MM-dd'), this.$formatDate(res.endTime, 'yyyy-MM-dd')];
        this.useTime = [this.$formatDate(res.validBeginDate, 'yyyy-MM-dd'), this.$formatDate(res.validEndDate, 'yyyy-MM-dd')];
        this.form.loopImgArr = res.loopImgArr;
        if (res.singleMax === 0) {
          this.form.singleMax = 0;
        } else if (res.singleMax > 0) {
          this.form.singleMax = res.singleMax;
        }

        if (this.form.loopImgArr.length !== 6) {
          // eslint-disable-next-line no-plusplus
          for (let i = this.form.loopImgArr.length; i < 6; i++) {
            this.form.loopImgArr.push({
              url: '',
              sort: i,
            });
          }
        }
        console.log(this.form.loopImgArr, '图片数组');
        console.log(res.items, '666666666666666666666');
        if (res.items.length) {
          this.form.items = res.items;
        }
      });
    },
    receive(value) {
      this.form.loopImgArr = value;
    },
    // 获取归属商户下拉列表
    getShopInfoSelect(query) {
      if (!query) return;
      const params = {
        agentName: query,
      };
      $agent_selectAgent(params).then((res) => {
        this.shopOptions = res;
      });
    },

    // 表单校验
    checkSpecificationsList() {
      let flag1 = true;
      let flag2 = true;
      let flag3 = true;
      let flag4 = true;
      let flag5 = true;
      let salePriceArr = [];
      let originPriceArr = [];
      let salePrieStr = '';
      let originPriceStr = '';
      for (let i = 0; i < this.form.items.length; i += 1) {
        if (!this.form.items[i].subTitle) {
          flag1 = false;
          break;
        }
        if (this.form.items[i].salePrice < 0.01 || this.form.items[i].salePrice > 9999999) {
          flag2 = false;
          break;
        }
        if (this.form.items[i].originPrice < 0.01 || this.form.items[i].originPrice > 9999999) {
          flag3 = false;
          break;
        }
        if (this.form.items[i].salePrice <= this.form.items[i].channelPrice) {
          flag4 = false;
          break;
        }
        if (this.form.items[i].originPrice <= this.form.items[i].salePrice) {
          flag5 = false;
          break;
        }
        // 判断是否小数位大于三位
        salePrieStr = this.form.items[i].salePrice.toString();
        salePriceArr = salePrieStr.split('.');
        if (salePriceArr[1]) {
          if (salePriceArr[1].length >= 3) {
            this.$message.error('团购价最多两位小数');
            return false;
          }
        }
        originPriceStr = this.form.items[i].originPrice.toString();
        originPriceArr = originPriceStr.split('.');
        console.log(originPriceStr, originPriceArr);
        if (originPriceArr[1]) {
          if (originPriceArr[1].length >= 3) {
            this.$message.error('原价最多两位小数');
            return false;
          }
        }
      }
      if (!flag1) {
        this.$message.error('商品规格名称必填');
        return false;
      }
      if (!flag2) {
        this.$message.error('商品团购价最小为0.01，最大为9999999');
        return false;
      }
      if (!flag3) {
        this.$message.error('商品原价最小为0.01，最大为9999999');
        return false;
      }
      if (!flag4) {
        this.$message.error('团购价不能小于或者等于成本价');
        return false;
      }
      if (!flag5) {
        this.$message.error('原价不能小于或者等于团购价');
        return false;
      }
      return true;
    },
    // eslint-disable-next-line consistent-return
    async checkForm() {
      await this.$validator({
        ...this.form,
      }, [
        'onlyName/require/商品标题',
        'remark/require/商品描述',
      ]);
      return true;
    },
    // 打开保存并发布提示弹窗
    async openSubmitDialog() {
      // 检验规格信息
      if (!this.checkSpecificationsList()) return;
      // 校验封面
      if (!this.checkImgList()) return;
      // 校验表单输入数据
      if (!await this.checkForm()) return;
      this.$refs.dialog.dialogs = {
        icon: '/img/dialog/exploreIcon.png',
        title: '确定发布上线',
        content: '发布成功后，用户端即刻展示，并且用户能够进行下单操作',
      };
      this.$refs.dialog.showDialog = true;
    },
    // 预览
    // eslint-disable-next-line vue/no-dupe-keys
    preview() {
      this.checkGoodsImg();
      this.$refs.previewDialog.openDialog();
    },
    // 新增规格信息
    // addSpecificationsInfo() {
    //   this.form.items.push({
    //     channelPrice: '', // 结算价
    //     itemChannelStock: '', // 库存
    //     originPrice: '', // 原价(分)
    //     salePrice: '', // 套餐售价(分)
    //     subTitle: '', // 套餐名称
    //   });
    // },
    // 删除规格信息
    // deleteSpecificationsInfo(i) {
    //   if (i) {
    //     this.form.items.splice(i, 1);
    //   }
    // },
    // 保存并发布
    submit() {
      this.$refs.dialog.showDialog = false;
      const form = {
        groupId: this.form.groupId,
        groupName: this.form.onlyName,
        items: this.form.items,
        loopImgArr: this.form.loopImgArr,
        remark: this.form.remark,
        // onlyName: this.form.onlyName,
        pubulish: 1,
      };
      // form.favorable = this.form.favorable ? 2 : 1;
      const params = {
        ...form,
      };
      const loading = this.$loading({
        lock: true,
        text: '正在发布',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      $sp_groupupdate(params).then((res) => {
        loading.close();
        this.code = res.code;
        if (this.code === 1000) {
          // 发布成功
          this.$refs.resultDialog.dialogs = {
            icon: '/img/dialog/icon-success.png',
            title: '发布成功!',
            done: '好的',
          };
          this.$refs.resultDialog.showDialog = true;
          this.timerId = setTimeout(() => {
            this.$router.go(-1);
            this.$refs.resultDialog.showDialog = false;
          }, 2000);
        } else {
          // 发布失败
          this.$refs.resultDialog.dialogs = {
            icon: '/img/dialog/icon-fail.png',
            title: '发布失败!',
            done: '重新发布',
          };
          this.$refs.resultDialog.showDialog = true;
          this.timerId = setTimeout(() => {
            this.$refs.resultDialog.showDialog = false;
          }, 2000);
        }
      }).catch(() => {
        // 发布失败
        this.$refs.resultDialog.dialogs = {
          icon: '/img/dialog/icon-fail.png',
          title: '发布失败!',
          done: '重新发布',
        };
        this.$refs.resultDialog.showDialog = true;
        this.timerId = setTimeout(() => {
          this.$refs.resultDialog.showDialog = false;
        }, 2000);
      }).finally(() => {
        loading.close();
      });
    },
    // 发布结果弹窗按钮点击事件
    doneBtn() {
      clearTimeout(this.timerId);
      if (this.code === 1000) {
        // 发布成功
        this.$router.go(-1);
      } else {
        // 发布失败 
        this.$refs.resultDialog.showDialog = false;
        this.submit();
      }
    },
    // 检验封面函数
    checkImgList() {
      // eslint-disable-next-line no-plusplus
      const result = this.form.loopImgArr.some((v) => v.url);
      if (result === false) {
        this.$message.error('请至少选择一张图片');
        return false;
      } else {
        return true;
      }
    },
    // 判断用户首页图片
    checkGoodsImg() {
      if (this.form.loopImgArr[0].url) {
        this.form.goodsPath = this.form.loopImgArr[0].url;
      } else {
        // eslint-disable-next-line no-restricted-syntax
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.form.loopImgArr.length; i++) {
          if (this.form.loopImgArr[i].url) {
            this.form.goodsPath = this.form.loopImgArr[i].url;
            break;
          }
        }
      }
    },
  },
};
</script>
<style lang="scss">
.returns {
  width: 100%;
  height: 50px;
  .btn {
    width: 290px;
    height: 50px;
    border-radius: 60px;
  }
}
.groupBuyingGoods {
  .mr {
    margin-right: 20px;
  }
  width: 100%;
  .basicInfo {
    width: 100%;
    border-radius: 12px;
    padding: 20px;
    box-sizing: border-box;
    color: #666;
    background-color: #fff;
    overflow: auto;
    .title {
      font-size: 15px;
      font-weight: bold;
    }

    .goodss {
      font-size: 14px;

      .tips {
        margin-left: 10px;
        margin-top: 10px;
        margin-left: 70px;
      }
    }
    .basicBox {
      display: flex;
      justify-content: space-around;
      .tips {
        width: 350px;
        font-size: 12px;
        color: #f63d29;
        margin-top: 10px;
        margin-left: 69px;
      }
      .label {
        width: 100px;
        text-align: right;
      }
      .el-checkbox__inner {
        border-radius: 50%;
      }
    }
  }
  .specificationInfo {
    .title {
      font-size: 15px;
      font-weight: bold;
    }
    margin-top: 20px;
    width: 100%;
    border-radius: 12px;
    padding: 20px;
    box-sizing: border-box;
    color: #666;
    background-color: #fff;
    overflow: auto;
    .content {
      > p {
        margin-top: 10px;
        margin-bottom: 5px;
        display: flex;
        span:nth-child(2) {
          width: 54%;
          margin-left: 20%;
        }
      }
      .specificationForm {
        height: 115px;
        margin-bottom: 10px;
        padding: 5px;
        border-radius: 10px;
        background: #f8f8f8;
        display: flex;
        .middleContent {
          flex: 1;
          display: flex;
          justify-content: center;
          position: relative;
          > span:first-child {
            height: 115px;
            border: 1px solid #eeeeee;
            position: absolute;
            left: 6%;
          }
          > span:nth-child(2) {
            height: 115px;
            border: 1px solid #eeeeee;
            position: absolute;
            right: 6%;
          }
        }
        .delectForm {
          margin-left: 10px;
          width: 40px;
          color: #ef3f46;
          background: #fff;
          border: none;
          border-radius: 10px;
          transition: all 0.5s;
        }
        .delectForm:hover {
          border: 1px solid #ef3f46;
          width: 80px;
        }
      }
    }
  }
  .goodsDetail {
    width: 100%;
    border-radius: 12px;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
    .title span:first-child {
      font-size: 15px;
      font-weight: bold;
    }
    .title span:last-child {
      font-size: 12px;
      color: #ef3f46;
      margin-left: 10px;
    }
    .btnBox {
      width: 100%;
      margin-top: 20px;
      .btn {
        width: 290px;
        height: 60px;
        border-radius: 60px;
      }
    }
  }
  .dialogBtn {
    width: 100%;
    margin-top: 60px;
    .btn {
      width: 200px;
      height: 50px;
      border-radius: 50px;
    }
  }
}
</style>
